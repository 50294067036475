import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/6A/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/6A/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/6A/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/6A/4.jpg';
import p5 from 'assests/Photos/ClassAssembly/6A/5.jpg';
import p6 from 'assests/Photos/ClassAssembly/6A/6.jpg';
import p7 from 'assests/Photos/ClassAssembly/6A/7.jpg';
import p8 from 'assests/Photos/ClassAssembly/6A/8.jpg';
import p9 from 'assests/Photos/ClassAssembly/6A/9.jpg';
import p10 from 'assests/Photos/ClassAssembly/6A/10.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';

const ClassAssembly6A = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    }, 
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },

      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },

      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },

      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },

      {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
      },
   
      {
        src: p10,
        source: p10,
        rows: 1,
        cols: 1,
      },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Sankranthi
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
    Class: 6A Date: 14 January 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 6A presented a colourful and informative assembly on the topic, ‘Sankranthi – the harvest festival’ on Saturday, 14 January 2023. The assembly highlighted the significance of the first festival of the year being celebrated across the country with different names. The scientific reasons behind the celebrations and the delicacies that are prepared during the festival were explained elaborately.
 <br></br>
        The students interspersed their assembly presentation with folk songs and a dance. The lyrics of the melodious folk song emphasised the delight that a farmer’s heart is filled with during the harvest. All the students were dressed in their traditional attire which was a treat to the eyes.
        <br></br>
        The assembly conveyed the essence of the festival Sankranti that one should be grateful for the land on which our food grows and to have generosity and gratitude deep in our hearts, spread peace, love and harmony among all.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
      The greatness of a culture can be found in its festivals!
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly6A;